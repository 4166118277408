import React, { FunctionComponent, useState } from 'react'
import Select from 'react-select'
import { dt } from '../../shared/dynamic-rs'
import { t } from 'ttag'

import { FilterInputType, getDataByKey, getLocalizedSelectValue } from '../PuxRepeater'
import ReferenceFilterControlGroup from './controls/ReferenceFilterControlGroup'

const rootClass = `reference-filter`

const pascalize = (str: string): string => {
  return str.charAt(0).toUpperCase() + str.replace(`w`, `W`).slice(1)
}

const PuxDesignReferenceFilter: FunctionComponent<FilterInputType> = (props) => {
  const data = props.completeData
  const [isOpen, setOpen] = useState(false)

  const rsType = dt(`Pux.RepeaterFilter.Reference.Type`)
  const rsFocus = dt(`Pux.RepeaterFilter.Reference.IndustryFocus`)
  const rsLabels = dt(`Pux.RepeaterFilter.Reference.IWantToSee`)
  const rsPrice = dt(`Pux.RepeaterFilter.Reference.Price`)
  const typeData = getDataByKey(data, props.activeFilters, `puxDesignReferenceType`, rsType)
  const labelsData = getDataByKey(data, props.activeFilters, `labels`, rsLabels)
  const focusData = getDataByKey(data, props.activeFilters, `puxDesignReferenceIndustryFocus`, rsFocus)

  const labelsSort = [
    `presentationweb`,
    `eshop`,
    `portal`,
    `enterprise`,
    `print`,
    `webdesign`,
    `content`,
    `identity`,
    `research`,
    `marketing`,
  ]
  labelsData?.sort((a, b) => (labelsSort.indexOf(a.value) > labelsSort.indexOf(b.value) ? 1 : -1))

  props.setBindingTable({
    'filter-referencetype': {
      field: `puxDesignReferenceType`,
    },
    'filter-industryfocus': {
      field: `puxDesignReferenceIndustryFocus`,
    },
    'filter-labels': {
      field: `labels`,
    },
    'filter-price-identity': {
      field: `puxDesignReferenceIdentityPrice`,
    },
    'filter-price-content': {
      field: `puxDesignReferenceContentPrice`,
    },
    'filter-price-enterprise': {
      field: `puxDesignReferenceEnterprisePrice`,
    },
    'filter-price-eshop': {
      field: `puxDesignReferenceEshopPrice`,
    },
    'filter-price-industry': {
      field: `puxDesignReferenceIndustryFocus`,
    },
    'filter-price-marketing': {
      field: `puxDesignReferenceMarketingPrice`,
    },
    'filter-price-portal': {
      field: `puxDesignReferencePortalPrice`,
    },
    'filter-price-presentationweb': {
      field: `puxDesignReferencePresentationWebPrice`,
    },
    'filter-price-print': {
      field: `puxDesignReferencePrintPrice`,
    },
    'filter-price-research': {
      field: `puxDesignReferenceResearchPrice`,
    },
    'filter-price-webdesign': {
      field: `puxDesignReferenceWebdesignPrice`,
    },
  })

  // reset urls in case of hidden filters
  if (
    !props.isReseting &&
    (!props.activeFilters || !props.activeFilters[`labels`])
  ) {
    props.updateUrl(``, `filter-industryfocus`)
    props.updateUrl(``, `filter-price-identity`)
    props.updateUrl(``, `filter-price-content`)
    props.updateUrl(``, `filter-price-enterprise`)
    props.updateUrl(``, `filter-price-eshop`)
    props.updateUrl(``, `filter-price-industry`)
    props.updateUrl(``, `filter-price-marketing`)
    props.updateUrl(``, `filter-price-portal`)
    props.updateUrl(``, `filter-price-presentationweb`)
    props.updateUrl(``, `filter-price-print`)
    props.updateUrl(``, `filter-price-research`)
    props.updateUrl(``, `filter-price-webdesign`)
  }

  const controlHandler = (filterValue: string, filterId: string) => {
    props.updateUrl(filterValue, filterId)
  }

  return (
    <>
      <button
        className={`pux-repeater-filter-link plus-icon${isOpen ? ` is-open` : ``}`}
        type="button"
        onClick={() => setOpen(!isOpen)}
      >{t`Pux.RepeaterFilter.OpenFilter`}</button>
      <div className={`pux-repeater-filter-wrapper ${rootClass}-wrapper${isOpen ? ` is-open` : ``}`}>
        <div className={`pux-repeater-filter-item`}>
          <label
            className={`pux-repeater-filter-label`}
            htmlFor="filter-labels"
          >{t`Pux.RepeaterFilter.Reference.IWantToSee.Label`}</label>
          <ReferenceFilterControlGroup
            controlHandler={controlHandler}
            activeFilter={props.activeFilters[`labels`]}
            groupId={`filter-labels`}
            formComponent="checkbox"
            controlItems={labelsData ?? []}
          />
          {/* <Select
          name="filter-labels"
          className="react-select-container"
          classNamePrefix="react-select"
          value={getLocalizedSelectValue(props.activeFilters[`labels`], `Pux.RepeaterFilter.Reference.IWantToSee`)}
          options={labelsData}
          onChange={selectedOption => props.updateUrl(selectedOption.value, `filter-labels`)}
        /> */}
        </div>
        {/*<div className={`pux-repeater-filter-item`}>
          <label
            className={`pux-repeater-filter-label`}
            htmlFor="filter-referencetype"
          >{t`Pux.RepeaterFilter.Reference.Type.Label`}</label>
          <ReferenceFilterControlGroup
            controlHandler={controlHandler}
            activeFilter={props.activeFilters[`puxDesignReferenceType`]}
            groupId={`filter-referencetype`}
            formComponent="checkbox"
            controlItems={typeData ?? []}
          />
          {/* <Select
          name="filter-referencetype"
          className="react-select-container"
          classNamePrefix="react-select"
          value={getLocalizedSelectValue(
            props.activeFilters[`puxDesignReferenceType`],
            `Pux.RepeaterFilter.Reference.Type`
          )}
          options={typeData}
          onChange={selectedOption => props.updateUrl(selectedOption.value, `filter-referencetype`)}
        />
        </div> */}
        { props.activeFilters &&
          props.activeFilters[`labels`] &&
          //props.activeFilters[`puxDesignReferenceType`] &&
          props.filteredData &&
          props.filteredData.length > 0 && (
              <>
                <div className={`pux-repeater-filter-item`}>
                  <label
                    className={`pux-repeater-filter-label`}
                    htmlFor={`filter-price-` + props.activeFilters[`labels`]}
                  >{t`Pux.RepeaterFilter.Reference.Price.Label`}</label>
                  <ReferenceFilterControlGroup
                    controlHandler={controlHandler}
                    activeFilter={
                      props.activeFilters[`puxDesignReference` + pascalize(props.activeFilters[`labels`]) + `Price`]
                    }
                    groupId={`filter-price-` + props.activeFilters[`labels`]}
                    formComponent="checkbox"
                    controlItems={
                      getDataByKey(
                        data,
                        props.activeFilters,
                        `puxDesignReference` + pascalize(props.activeFilters[`labels`]) + `Price`,
                        rsPrice
                      ) ?? []
                    }
                  />
                  {/* <Select
                  name="filter-referencetype"
                  className="react-select-container"
                  classNamePrefix="react-select"
                  value={getLocalizedSelectValue(
                    props.activeFilters[`puxDesignReference` + pascalize(props.activeFilters[`labels`]) + `Price`],
                    `Pux.RepeaterFilter.Reference.Price`
                  )}
                  options={getDataByKey(
                    data,
                    props.activeFilters,
                    `puxDesignReference` + pascalize(props.activeFilters[`labels`]) + `Price`,
                    rsPrice
                  )}
                  onChange={selectedOption =>
                    props.updateUrl(selectedOption.value, `filter-price-` + props.activeFilters[`labels`])
                  }
                /> */}
                </div>
                <div className={`pux-repeater-filter-item`}>
                  <label
                    className={`pux-repeater-filter-label`}
                    htmlFor="filter-industryfocus"
                  >{t`Pux.RepeaterFilter.Reference.IndustryFocus.Label`}</label>
                  <ReferenceFilterControlGroup
                    controlHandler={controlHandler}
                    activeFilter={props.activeFilters[`puxDesignReferenceIndustryFocus`]}
                    groupId={`filter-industryfocus`}
                    formComponent="checkbox"
                    controlItems={focusData ?? []}
                  />
                  {/* <Select
                  name="filter-industryfocus"
                  className="react-select-container"
                  classNamePrefix="react-select"
                  value={getLocalizedSelectValue(
                    props.activeFilters[`puxDesignReferenceIndustryFocus`],
                    `Pux.RepeaterFilter.Reference.IndustryFocus`
                  )}
                  options={focusData}
                  onChange={selectedOption => props.updateUrl(selectedOption.value, `filter-industryfocus`)}
                /> */}
                </div>
            </>
          )}
        <button
          className={`pux-repeater-filter-link cross-icon`}
          type="button"
          onClick={props.clearFilter}
        >{t`Pux.RepeaterFilter.Clear`}</button>
      </div>
    </>
  )
}

export default PuxDesignReferenceFilter
