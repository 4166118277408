import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout/layout'
import PuxMetaTags from '../components/shared/PuxMetaTags'
import WidgetBuilder from '../components/builders/widget-builder'
import { Icon } from '../components/builders/icon-builder'
import { Reference, ReferenceListPageProps } from '../types/reference.types'
import { PuxFilter } from '../components/filter/pux-filter'
import BreadCrumbs from '../components/BreadCrumbs'
import PuxRepeater from '../components/PuxRepeater/PuxRepeater'

const ReferenceList = ({ data, pageContext }: ReferenceListPageProps) => {
  const page = data.orchard.puxDesignReferenceList[0]

  const mappedItems = pageContext.items.map(p => {
    p.labels = p.puxDesignReferencePriceList?.contentItems?.map(price => price.puxDesignReferencePriceLabel)
    return p
  })

  return (
    <Layout localizedPath={page.localization?.localizations}>
      <PuxMetaTags {...pageContext.metaTagsData} />
      <BreadCrumbs
        default={true}
        center={false}
        items={[
          {
            path: page.path,
            displayText: page.displayText,
          },
        ]}
      />

      <div className="pux-container center-content">
        <h1>{page.puxDesignReferenceListDisplayTitle}</h1>
      </div>
      <div className="references-container">
        <PuxRepeater
          repeaterData={mappedItems}
          simpleRepeaterOnePageOnly={false}
          simpleRepeaterPageSize={9}
          simpleRepeaterPagerOptions={`showall`}
          puxSimpleFilter={{ puxFilterIsHidden: false }}
          contentItemId={`123456789`}
        ></PuxRepeater>
      </div>
      {/* <PuxFilter data={pageContext.items} settings={{ url: pageContext.fetchUrl }} /> */}
    </Layout>
  )
}

export default ReferenceList

export const query = graphql`
  query($pagePath: String = "") {
    orchard {
      puxDesignReferenceList(first: 1, where: { path: $pagePath }) {
        path
        displayText
        puxDesignReferenceListDisplayTitle
        localization {
          localizations {
            ... on Orchard_PuxDesignReferenceList {
              path
              published
              localization {
                culture
              }
            }
          }
        }
      }
    }
  }
`
