import React, { FunctionComponent } from 'react'
import { dt } from '../../../shared/dynamic-rs'
import {navigate } from 'gatsby'

interface ReferenceFilterControlItemProps {
  controlHandler: (filterValue: string, filterId: string) => void
  activeFilter: string
  groupId: string
  formComponent: 'radio' | 'checkbox'
  value: string
  label: string
  count: string
}

const ReferenceFilterControlItem: FunctionComponent<ReferenceFilterControlItemProps> = props => {
  const handleClick = (e: any) => {
    if (e.target.value === props.activeFilter) {
      props.controlHandler('', props.groupId)
    } else {
      props.controlHandler(e.target.value, props.groupId)
    }
  }
  return (
    <>
      <input
        type={props.formComponent}
        name={props.groupId}
        id={`${props.groupId}-${props.value}`}
        value={props.value}
        checked={props.activeFilter === props.value}
        onChange={e => handleClick(e)}
        
      />
      <label htmlFor={`${props.groupId}-${props.value}`}>
        {dt(props.label)} 
      </label>
    </>
  )
}

export default ReferenceFilterControlItem
