import React, { FunctionComponent } from 'react'
import ReferenceFilterControlItem from './ReferenceFilterControlItem'
import { t } from 'ttag'

interface ReferenceFilterControlGroupProps {
  // controlGroup: string
  // controlItem: PuxFilterControlItemValue
  // dynamicHandler: (e: React.ChangeEvent<HTMLInputElement>) => void
  // dynamicControls: DynamicControls
  controlHandler: (filterValue: string, filterId: string) => void
  activeFilter: string
  groupId: string
  formComponent: 'checkbox' | 'radio'
  controlItems: object[]
}

const ReferenceFilterControlGroup: FunctionComponent<ReferenceFilterControlGroupProps> = props => {
  const [showAll, setShowAll] = React.useState(false)
  const allItems = props.controlItems
  let visibleItems = undefined

  if (allItems.length) {
    if (!showAll) {
      visibleItems = allItems.slice(0, 5)
    } else {
      visibleItems = allItems
    }

    if (props.groupId.indexOf("price") > -1) {     
      visibleItems.sort((a: object, b: object) => {
        return parseInt(a.value) - parseInt(b.value)
      })
    }   

    return (
      <div className={`reference-filter-control-group`}>
        {visibleItems.map((item, idx) => (
          <ReferenceFilterControlItem
            key={idx}
            controlHandler={props.controlHandler}
            activeFilter={props.activeFilter}
            groupId={props.groupId}
            formComponent={props.formComponent}
            {...item}
          />
        ))}
        {allItems.length > 5 && !showAll && (
          <button
            className={`pux-repeater-filter-link`}
            onClick={() => setShowAll(true)}
          >{t`Puxdesign.Filter.ShowMore`}</button>
        )}
      </div>
    )
  }

  return null
}

export default ReferenceFilterControlGroup
