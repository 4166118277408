import { Link } from 'gatsby'
import React, { FunctionComponent } from 'react'
import { Reference } from '../../types/reference.types'
import { t } from 'ttag'
import { useInView } from 'react-intersection-observer'
import { motion } from 'framer-motion'
import { getHrefLang, getLocalizedUrl } from '../../utils/localeURL'

interface ReferenceCardProps {
  item: Reference
}

export const ReferenceCard: FunctionComponent<ReferenceCardProps> = props => {
  const [ref, inView] = useInView({
    threshold: 0.3,
    triggerOnce: true,
  })

  const variants = {
    visible: { opacity: 1, y: 0 },
    hidden: {
      opacity: 0,
      y: 100,
    },
  }

  return (
    <motion.div
      ref={ref}
      className="reference-wrap"
      key={props.item.contentItemId}
      animate={inView ? `visible` : `hidden`}
      variants={variants}
      transition={{ duration: 0.4, ease: `easeOut` }}
    >
      <Link hrefLang={getHrefLang(props.item.path)} to={getLocalizedUrl(props.item.path)} className="reference">
        <div
          className="reference-img"
          style={{
            backgroundImage: `url('${props.item.puxDesignReferencePreviewImage?.puxMediaSelectorImage.resizePaths[0]}')`,
          }}
        >
          {props.item.puxDesignReferenceType === `casestudy` && (
            <div className="case-study-tag">{t`PuxDesign.CaseStudy`}</div>
          )}
        </div>
        <div className="reference-text">
          <div className="reference-title">{props.item.puxDesignReferenceDisplayTitle ? props.item.puxDesignReferenceDisplayTitle : props.item.displayText}</div>
          {/*
          <div
            className="reference-description"
            dangerouslySetInnerHTML={{ __html: props.item.referencePreviewText.html?.replace(/font-size: 1rem;/g, ``) }}
          ></div>
          */}
        </div>
      </Link>
    </motion.div>
  )
}
